const data = {
    table: {
        columns: [
            { title: '名称', field: 'name', width: '300', align: 'left' },
            { title: '性别', field: 'gender', width: '' },
            { title: '最小年龄', field: 'minAge', width: '' },
            { title: '最大年龄', field: 'maxAge', width: '' },
            { title: '排序', field: 'sort', width: '' },
            { title: '排序', field: 'feeHeji', width: '' },
            { title: '排序', field: 'feeZhehou', width: '' },
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 20
    },
}

export default data
